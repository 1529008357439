import React from 'react'
import "./roadmap.css"
function Roadmappage() {
  return (
    <div className='roadmap'>
      
      <div className='roadmap-wrapper'>
        
        <div className='roadmap-text-wrapper'>
        <h1>Roadmap</h1>
        <p>.</p>
        </div>

        <div className='roadmap-slider'>
            <div className='box'>
                <span>✦ Phase 1</span>
                <p>Genesis and distribution</p>
                <img src="./img/phase1pic.png" />
            </div>
            
            <div className='box'>
                <span>✦ Phase 2</span>
                <p>Memetic events and partnerships</p>
                <img src="./img/phase2pic.png" />
            </div>
            
            <div className='box'>
                <span>✦ Phase 3</span>
                <p>Integration with Bitcoin services.</p>
                <img src="./img/phase3pic.png" />
            </div>
            
        </div>
      </div>
        <div className='dashed-line'></div>
      
    
    </div>
  )
}

export default Roadmappage
