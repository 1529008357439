import { LuAirVent, LuBitcoin, LuCoins, LuDna, LuUsers2 } from 'react-icons/lu';
import './App.css';
import Navbar from './Navbar';
import Heropage from './Heropage';
function Distributionpage() {
  return (
    <>
    <div className='sectionDistribution'>
      <div className='sectionDistributionContainer'>
        <img src='./img/graph.png'></img>
        <div className='sectionDisInfoContainer'>
          <h1>GOATonomics <span style={{fontFamily:'base'}}>Distribution</span><span style={{color:'yellow'}}>.</span></h1>
          <h2><span style={{color:"#ffc800"}}>■</span>Community and marketing</h2>
          <h2><span style={{color:"#46b1e1"}}>■</span>Liquidity Pool</h2>
          <h2><span style={{color:"#e8e8e8"}}>■</span>Development Fund</h2>
          <h2><span style={{color:"#f2aa84"}}>■</span>CEX</h2>
          <h2><span style={{color:"#d492cd"}}>■</span>Staking Rewards</h2>
          <h2><span style={{color:"#18dea6"}}>■</span>Ecosystem Growth</h2>

        </div>
      </div>
    </div>
    </>
  );
}

export default Distributionpage;
