import React from 'react'
import "./Dexscreener.css"
function Dexscreener() {
  return (
    <div className='dexscreenerpage'>
      <div className='dexscreenerpage-wrapper'>
        <a href='https://dexscreener.com/base/0xdcfd79c489a86adf532eb84f38daca9bb545f9e6' className='dexscreener'>
          <img src='/img/images.png'></img>
        </a>
        <a href='https://www.dextools.io/app/en/base/pair-explorer/0xdcfd79c489a86adf532eb84f38daca9bb545f9e6?t=1719413978680' className='dextools'>
          <img src='./img/DEXTools_ black.png'></img>
        </a>
        <a href='https://basescan.org/token/0xf0268c5f9aa95baf5c25d646aabb900ac12f0800' className='basechain'>
          <img src='./img/basescan.png'></img>
        </a>
      </div>

    </div>
  )
}

export default Dexscreener
