import React from 'react'
import "./Goatonomics.css"
function Goatonomics() {
  return (
    <div className='goatNomics'>
      <div className='goatNomics-wrapper'>
        <h1>GOAT<span style={{fontFamily:'base'}}>onomics</span><span style={{color:'yellow'}}>.</span></h1>
        <img src="./img/coinStackpng.png" />
        <div className='textWrapper'>
          <p>Total Token Supply</p>
          <h1>21 trillion GOAT</h1>
        </div>
      </div>
      <img className='blurredCoin1' src="./img/coinBlur.png" />
      <img className='blurredCoin2' src="./img/coinBlur.png" />
    </div>
  )
}

export default Goatonomics
