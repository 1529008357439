import { LuAirVent, LuBitcoin, LuCoins, LuDna, LuUsers2 } from 'react-icons/lu';
import './App.css';
import Navbar from './Navbar';
import Heropage from './Heropage';
function Usecasepage() {
  return (
    <>
    <div className='sectionUsecase'>
    <h1>useCase of <span style={{fontFamily:'baseBold'}}>GOAT</span><span style={{fontWeight:'bold', color:'yellow'}}>.</span></h1>

    <div className='sectionUsecaseContainer'>
      <div className='boxContainer'>
        <h1>GOAT Chain BTC L2</h1>
        <h2>
        GOAT introduces the GOAT Chain, a layer 2 solution built on top of Bitcoin. It enhances scalability, reduces transaction fees, and enables faster confirmations for GOAT transactions. The GOAT Chain operates seamlessly alongside the Bitcoin mainnet.
        </h2>
        <img src='./img/chain.png'></img>

      </div>
      <div className='boxContainer'>
        <h1>Gaming</h1>
        <h2>
        GOAT embraces the playful spirit of memes by integrating gaming elements. Users can participate in GOAT-themed games, challenges, and competitions. Prizes and rewards are distributed in GOAT tokens.
        </h2>
        <img src='./img/console.png'></img>

      </div>
    </div>

    <div className='boxContainer'>
        <h1>GOAT Card</h1>
        <h2>
        GOAT holders can obtain limited-edition GOAT cards featuring iconic BTC-related artwork. These cards serve as collectibles and grant exclusive access to GOAT events and promotions.
        </h2>
        <img src='./img/credit.png'></img>

      </div>

      
    <div className='sectionUsecaseContainer'>
      <div className='boxContainer'>
        <h1>GOAT Merchandise</h1>
        <h2>
        GOAT enthusiasts can purchase branded merchandise, including apparel, accessories, and memorabilia. All proceeds contribute to the growth and development of the GOAT ecosystem.
        </h2>
        <img src='./img/merchandise.png'></img>

      </div>
      <div className='boxContainer'>
        <h1>GOAT NFTs</h1>
        <h2>
        GOAT NFTs (Non-Fungible Tokens) represent unique digital assets tied to BTC-related themes. These NFTs can be traded, collected, and showcased within the GOAT community.
        </h2>
        <img src='./img/nft.png'></img>
      </div>
    </div>

    <div className='boxContainer'>
        <h1>GOAT Club</h1>
        <h2>
        The GOAT Club is an exclusive membership program for dedicated GOAT holders. Club members gain early access to new features, events, and collaborations. Membership tiers offer additional perks and benefits.
        </h2>
        <img src='./img/com.png'></img>

      </div>
    </div>
    </>
  );
}

export default Usecasepage;
