import { LuAirVent, LuBitcoin, LuCoins, LuDna, LuUsers2 } from 'react-icons/lu';
import './App.css';
import Navbar from './Navbar';
import Heropage from './Heropage';
function Aboutpage() {
  return (
    <>
    <div className='sectionAbout'>
        <div  className='sectionAboutContainer1'>
          <img src='./img/aboutimg.png'></img>
        </div>
      
        <div  className='sectionAboutContainer2'>
          <h1>about <span style={{fontFamily:'baseBold'}}>GOAT</span><span style={{fontWeight:'bold', color:'yellow'}}>.</span></h1>
          <h2>
          The GOAT ‘Greatest of All Tokens’ is a novel meme token built on the Bitcoin blockchain using the BRC-20 token standard. Inspired by the legendary status of Bitcoin BTC as the original and most inﬂuential cryptocurrency, GOAT aims to capture the essence of BTC’s dominance while adding a playful twist. In this whitepaper, we delve into the concept, mechanics, and vision behind GOAT.
          <br/>
          Bitcoin, often referred to as the “digital gold,” has established itself as the pioneer and leader in the crypto space. GOAT leverages the BRC-20 token standard to create a playful and community driven meme coin that pays homage to BTC.
          </h2>
        </div>

        <div className='goatBanner'>
        <h1>GOAT</h1>
        <img src='./img/favicon.png' style={{height:'80%', margin:'auto 0',mixBlendMode: 'luminosity' }}></img>
        <h1>GOAT</h1>
        <img src='./img/favicon.png' style={{height:'80%', margin:'auto 0',mixBlendMode: 'luminosity' }}></img>
        <h1>GOAT</h1>
        <img src='./img/favicon.png' style={{height:'80%', margin:'auto 0',mixBlendMode: 'luminosity' }}></img>
        <h1>GOAT</h1>
        <img src='./img/favicon.png' style={{height:'80%', margin:'auto 0',mixBlendMode: 'luminosity' }}></img>
        <h1>GOAT</h1>
        <img src='./img/favicon.png' style={{height:'80%', margin:'auto 0',mixBlendMode: 'luminosity' }}></img>
        <h1>GOAT</h1>
        <img src='./img/favicon.png' style={{height:'80%', margin:'auto 0',mixBlendMode: 'luminosity' }}></img>
        <h1>GOAT</h1>
        <img src='./img/favicon.png' style={{height:'80%', margin:'auto 0',mixBlendMode: 'luminosity' }}></img>
        <h1>GOAT</h1>
        <img src='./img/favicon.png' style={{height:'80%', margin:'auto 0',mixBlendMode: 'luminosity' }}></img>        
        </div>
    </div>
    </>
  );
}

export default Aboutpage;
