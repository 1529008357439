import React from 'react'
import './footer.css'

export default function Footer() {
    return (
        <div className='footer'>
            <div className='wrapper'>
                <div className='upperContainer'>
                    <img className='footerlogo' src='./img/GOATlogo.png' />
                    <div className='textContainer'>
                        <h1> GOAT Coin<span>.</span></h1>
                        <p> The 'Greatest Of All Tokens' </p>
                    </div>
                    <h1 className='btnContainer'> Buy GOAT </h1>

                </div>
                <div className='lowerContainer'>
                    <div className='disclaimer'>
                        <div className='contentContainer'>
                            <h1>Disclaimer<span>.</span></h1>
                            <p>GOAT is a meme token created for entertainment purposes. It does not represent ﬁnancial advice or investment recommendations. Always do your own research before participating in any token project.
                            </p>
                        </div>
                        <div className='bwLogoContainer'>
                            <p>© GOAT Coin</p>
                            <div className='bwLogo'>
                                <img src="./img/bwGoatlogo.png"/>
                            </div>

                        </div>
                    </div>
                    <div className='community'>
                        <div className='communityWrapper'>
                            <h1>Join the Community</h1>
                            <div className='socialmedia'>
                                <p>Telegram</p>
                                <p>Twitter</p>
                            </div>
                            
                        </div>
                        <div className='communitybtn'>
                            <p>Home</p>
                            <p>Features</p>
                            <p>Roadmap</p>
                            <p><a href="mailto:realgoatcoin@gContactmail.com">realgoatcoin@gmail.com</a></p>
                            <p><a href='mailto:info@realgoat.org'>info@realgoat.org</a> </p>


                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}
