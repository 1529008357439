import React from 'react'
import "./navbar.css"
function Navbar() {
  return (
    <div className='navbar'>
      
      <div className='logo-container'>
        <div>
        <img src="./img/favicon.png" />
        </div>
      </div>

      <div className='nav-categories'>
        <p>Home</p>
        <p>Features</p>
        <p>Tokenomics</p>
        <p>Roadmap</p>
      </div>

    <div className='nav-button'>
        <p>Launch D'App</p>
    </div>

    </div>
  )
}

export default Navbar
