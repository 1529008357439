import React from 'react'

export default function Mutatepage() {
    return (
        <div className='sectionMutate'>
            <h1 style={{color:'white', textAlign:'center', fontSize:'3rem',fontWeight:100}}><span style={{fontFamily:'baseBold'}}>GOAT</span> can mutate <span style={{fontWeight:'bold', color:'yellow'}}>.</span></h1>

            <div className='boxContainer' style={{background: 'linear-gradient(0deg, #18dea629, transparent, transparent)', border: 'none', display: 'flex', flexDirection: 'column', alignItems: 'center', height: 'fit-content'}}>
                <h1>Imagine GOATs frolicking in the digital meadows, their genes mutating like mad.</h1>
                <h2 style={{fontSize:'1.4rem', textAlign:'center'}}>Crossbreeding memes? Absolutely A dash of Dogecoin here, a sprinkle of Shiba Inu there – voilà, a new GOAT variant</h2>
                <img src='./img/mutate.png' style={{height:'35vh'}}></img>
            </div>
        </div>
    )
}
