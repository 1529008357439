import React, { useState, useEffect } from 'react';


export default function AnimatedLogo() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    const images = document.querySelectorAll('.image-container img');
    let currentIndex = 0;
    let counter = 0;
  
    function showNextImage() {
      const previousIndex = currentIndex === 0 ? images.length - 1 : currentIndex - 1;
  
      // Gradually fade out previous image
      let opacity = 1;
      const fadeOutInterval = setInterval(() => {
        opacity -= 0.10; // Adjust fade speed
        images[previousIndex].style.opacity = opacity;
        if (opacity <= 0) {
          clearInterval(fadeOutInterval);
          images[previousIndex].style.opacity = 0;
        }
      }, 1); // Adjust fade interval
  
      // Gradually fade in next image
      opacity = 0;
      const fadeInInterval = setInterval(() => {
        opacity += 0.60; // Adjust fade speed
        images[currentIndex].style.opacity = opacity;
        if (opacity >= 1) {
          clearInterval(fadeInInterval);
          images[currentIndex].style.opacity = 1;
        }
      }, 1); 
  
      currentIndex = (currentIndex + 1) % images.length;
      
      counter++;
      if (counter === 40) {
        clearInterval(imageSwitchInterval); // Stop the image switching
        images[currentIndex-1].style.opacity = 0; // Ensure the still image is fully visible

        images[currentIndex].src = './img/avatar/02.png'; // Switch to the still image
        images[currentIndex].style.opacity = 1; // Ensure the still image is fully visible
      }
    }
  
    // Show the first image
    images[currentIndex].style.opacity = 1;
  
    // Change images every 100ms for 20 times
    const imageSwitchInterval = setInterval(showNextImage, 100); 
  }, []);

  return (
    <div className='goatHomeContainer'>
      <div className='goatbubble1desc'>
        <h2>
          Bringing the GOAT of all memes
          <br></br>
          <span>Just as bitcoin reigns the supreme world of crypto, GOAT captures this greatness and humour</span>
        </h2>
      </div>
      <div className="image-container" style={{flex:1}}>
        <img src="./img/avatar/01.png" alt="Image 1" className="image1" />
        <img src="./img/avatar/02.png" alt="Image 2" className="image2" />
        <img src="./img/avatar/03.png" alt="Image 3" className="image3" />
        <img src="./img/avatar/04.png" alt="Image 3" className="image3" />
        <img src="./img/avatar/05.png" alt="Image 4" className="image4" />
        <img src="./img/avatar/06.png" alt="Image 5" className="image5" />
        <img src="./img/avatar/07.png" alt="Image 6" className="image6" />
      </div>
      <div className='goatbubble1desc'>
        <h2 style={{ margin: 'auto 0', marginLeft: '0px', marginRight: 'auto', color: 'white', borderTopLeftRadius: '0px', borderBottomRightRadius: '3rem'}}>
        BTC is the GOAT
        <br></br>
        GOAT is the MEME 
        </h2>
      </div>
    </div>

  );
}

