import { LuAirVent, LuBitcoin, LuCoins, LuDna, LuUsers2 } from 'react-icons/lu';
import './App.css';
import AnimatedLogo from './AnimatedLogo';
function Heropage() {
  return (
    <>
    <div className='sectionHero' >
      <div className='SectionHeroMainContainer' style={{backgroundImage:"url(/img/bg.jpg)"}}>
        <h1 className='herosectionTitle'>meet <span style={{fontFamily:'baseBold'}}>Goat</span><span style={{color:'yellow'}}>.</span></h1>
        <h1 className='herosectionSubtitle' style={{opacity:0.4, fontSize:'1.8rem',margin:0}}>Presenting  ‘Greatest of All Tokens’</h1>
        {/* <img src='./img/avatar/02.png' alt='mainlogo' className='mainLogo'></img> */}
        <AnimatedLogo></AnimatedLogo>
      </div>
      
      <div className='sectionHeroInfoContainer'>
        <div className='sectionHeroInfoItem'>
          <LuCoins></LuCoins>
          <h1>Greatest of All Tokens</h1>
          <h2>GOAT represents the pinnacle of memetic value</h2>
        </div>
        <div className='sectionHeroInfoItem'>
          <LuDna></LuDna>
          <h1>Immutable Ledger</h1>
          <h2>Built on the secure and decentralized Bitcoin blockchain</h2>
        </div>
        <div className='sectionHeroInfoItem'>
          <LuBitcoin></LuBitcoin>
          <h1>BRC20 Compatibility</h1>
          <h2>allowing seamless integration with existing wallets</h2>
        </div>
        <div className='sectionHeroInfoItem'>
          <LuUsers2></LuUsers2>
          <h1>Fair Distribution</h1>
          <h2> Airdrops, staking, and community participation</h2>
        </div>
        
      </div>
    </div>
    </>
  );
}

export default Heropage;
