import { LuAirVent, LuBitcoin, LuCoins, LuDna, LuUsers2 } from 'react-icons/lu';
import './App.css';
import Navbar from './Navbar';
import Heropage from './Heropage';
import Aboutpage from './Aboutpage';
import Usecasepage from './Usecasepage';
import Goatonomics from './Goatomicspage';
import Roadmappage from './Roadmappage';
import Distributionpage from './Distributionpage';
import FooterPage from './Footer';
import Mutatepage from './Mutatepage';
import Dexscreener from './Dexscreener';
function App() {
  return (
    <>
    <Navbar/>
    <Heropage></Heropage>
    <Aboutpage></Aboutpage>
    <Usecasepage></Usecasepage>
    <Goatonomics></Goatonomics>
    <Dexscreener></Dexscreener>
    <Distributionpage></Distributionpage>
    <Mutatepage></Mutatepage>
    <Roadmappage></Roadmappage>
    <FooterPage></FooterPage>
    </>
  );
}

export default App;
